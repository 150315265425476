import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['portlandContainer', 'notPortlandContainer']

  async connect () {
    if (typeof (google) !== 'undefined') {
      await google.maps.importLibrary('places')
      this.#geolocate()
    }
  }

  #geolocate () {
    if (!navigator.geolocation) {
      if (this.hasPortlandContainerTarget) {
        // Geolocation is not supported in this browser.
        this.#notPortland()
      }
    } else {
      navigator.geolocation.getCurrentPosition(this.#success.bind(this), this.#notPortland.bind(this))
    }
  }

  #success (position) {
    const geocoder = new google.maps.Geocoder()
    const { latitude, longitude } = position?.coords
    const latlng = {
      lat: latitude,
      lng: longitude
    }

    geocoder.geocode({ location: latlng }, (results, status) => {
      if (results && status === 'OK') {
        const addressComponents = results[0].address_components

        if (addressComponents !== undefined) {
          const formattedAddress = this.#formatAddressComponents(addressComponents)

          const city = formattedAddress.locality

          if (city === 'Portland') {
            this.#isPortland()
          } else {
            this.#notPortland()
          }
        }
      } else {
        this.#notPortland()
      }
    })
  }

  #isPortland () {
    this.portlandContainerTargets.map((element) => (element.classList.remove('hidden')))
    this.notPortlandContainerTargets.map((element) => (element.classList.add('hidden')))
  }

  #notPortland () {
    this.portlandContainerTargets.map((element) => (element.classList.add('hidden')))
    this.notPortlandContainerTargets.map((element) => (element.classList.remove('hidden')))
  }

  #formatAddressComponents (addressComponents) {
    const data = {}

    addressComponents.forEach((component) => {
      const type = component.types[0]

      data[type] = component.long_name
    })

    return data
  }
}
