import { Controller } from '@hotwired/stimulus'
import Cookies from 'js-cookie'

const ICON = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyMSAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS41MzY4IDIzLjAwNjhDMTQuMTc1OCAyMC44MzYzIDIwLjM2ODIgMTUuMjgwMSAyMC4zNjgyIDEwLjkzNDFDMjAuMzY4MiA1LjQxMTIzIDE1Ljg5MSAwLjkzNDA4MiAxMC4zNjgyIDAuOTM0MDgyQzQuODQ1MzIgMC45MzQwODIgMC4zNjgxNjQgNS40MTEyMyAwLjM2ODE2NCAxMC45MzQxQzAuMzY4MTY0IDE1LjI4MDEgNi41NjA1IDIwLjgzNjMgOS4xOTk1IDIzLjAwNjhDOS44ODQzNCAyMy41NzAxIDEwLjg1MiAyMy41NzAxIDExLjUzNjggMjMuMDA2OFpNMTAuMzY4MiAxMy45MzQxQzEyLjAyNSAxMy45MzQxIDEzLjM2ODIgMTIuNTkwOSAxMy4zNjgyIDEwLjkzNDFDMTMuMzY4MiA5LjI3NzIzIDEyLjAyNSA3LjkzNDA4IDEwLjM2ODIgNy45MzQwOEM4LjcxMTMxIDcuOTM0MDggNy4zNjgxNiA5LjI3NzIzIDcuMzY4MTYgMTAuOTM0MUM3LjM2ODE2IDEyLjU5MDkgOC43MTEzMSAxMy45MzQxIDEwLjM2ODIgMTMuOTM0MVoiIGZpbGw9IiMzMzU1RkYiLz4KPC9zdmc+Cg=='

const ICON_HOVERED = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyMSAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS41MzY4IDIzLjAwNjhDMTQuMTc1OCAyMC44MzYzIDIwLjM2ODIgMTUuMjgwMSAyMC4zNjgyIDEwLjkzNDFDMjAuMzY4MiA1LjQxMTIzIDE1Ljg5MSAwLjkzNDA4MiAxMC4zNjgyIDAuOTM0MDgyQzQuODQ1MzIgMC45MzQwODIgMC4zNjgxNjQgNS40MTEyMyAwLjM2ODE2NCAxMC45MzQxQzAuMzY4MTY0IDE1LjI4MDEgNi41NjA1IDIwLjgzNjMgOS4xOTk1IDIzLjAwNjhDOS44ODQzNCAyMy41NzAxIDEwLjg1MiAyMy41NzAxIDExLjUzNjggMjMuMDA2OFpNMTAuMzY4MiAxMy45MzQxQzEyLjAyNSAxMy45MzQxIDEzLjM2ODIgMTIuNTkwOSAxMy4zNjgyIDEwLjkzNDFDMTMuMzY4MiA5LjI3NzIzIDEyLjAyNSA3LjkzNDA4IDEwLjM2ODIgNy45MzQwOEM4LjcxMTMxIDcuOTM0MDggNy4zNjgxNiA5LjI3NzIzIDcuMzY4MTYgMTAuOTM0MUM3LjM2ODE2IDEyLjU5MDkgOC43MTEzMSAxMy45MzQxIDEwLjM2ODIgMTMuOTM0MVoiIGZpbGw9IiNFQTQ3NDciLz4KPC9zdmc+Cg=='

export default class ListingMap extends Controller {
  static values = {
    lat: { type: Number, default: 45.5152 },
    lon: { type: Number, default: -122.676483 }
  }

  static targets = ['map', 'item', 'container', 'toggler']

  async connect () {
    if (typeof (google) !== 'undefined') {
      await google.maps.importLibrary('maps')

      this.PortlandLatLng = new google.maps.LatLng(this.latValue, this.lonValue)
      this.infowindow = new google.maps.InfoWindow({
        maxWidth: 300
      })

      this.map = await this.#map()
      this.markers = await this.#setMarkers()
      this.#fitBounds()
    }
  }

  toggleMap (e) {
    e.target.checked ? this.#showMap() : this.#hideMap()
  }

  toggleMobileMap () {
    this.containerTarget.classList.toggle('wrapper-mobile_map')
  }

  async #map () {
    if (this.hasMapTarget) {
      const options = {
        // For now default is Portland coordinates
        center: this.PortlandLatLng,
        zoom: 10,
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_TOP
        }
      }

      window.googleMap = new google.maps.Map(this.mapTarget, options)
    }

    return window.googleMap
  }

  async #setMarkers () {
    const markers = []

    this.itemTargets.forEach(item => {
      const { lat, lng } = item.dataset

      if (!lat || !lng) {
        return
      }

      const marker = new google.maps.Marker({
        icon: ICON,
        position: new google.maps.LatLng(lat, lng),
        map: this.map
      })

      item.addEventListener('mouseover', () => marker.setIcon(ICON_HOVERED))
      item.addEventListener('mouseout', () => marker.setIcon(ICON))

      this.#addMarkerInfoWindow(marker, item)
      markers.push(marker)
    })

    return markers
  }

  #fitBounds () {
    if (!this.markers.length) {
      this.map.setZoom(10)
      return this.map.setCenter(this.PortlandLatLng)
    }

    const bounds = new google.maps.LatLngBounds()
    this.markers.forEach(marker => bounds.extend(marker.getPosition()))
    this.map.fitBounds(bounds, { top: 40, right: 40, bottom: 150, left: 40 })
  }

  #addMarkerInfoWindow (marker, item) {
    marker.addListener('click', () => {
      if (this.infowindow) {
        this.infowindow.close()
      }

      this.infowindow.setContent(item.outerHTML)

      this.infowindow.open({
        anchor: marker,
        map: window.map
      })
    })
  }

  #showMap () {
    this.containerTarget.classList.add('wrapper-map')
    Cookies.remove('is_map_hidden')
  }

  #hideMap () {
    this.containerTarget.classList.remove('wrapper-map')
    Cookies.set('is_map_hidden', true)
  }

  #removeMarkers () {
    this.markers.forEach(marker => marker.setMap(null))
  }

  #removeEventListeners () {
    this.itemTargets.forEach(item => {
      item.removeEventListener('mouseover', () => null)
      item.removeEventListener('mouseout', () => null)
    })
  }

  disconnect () {
    this.#removeMarkers()
    this.#removeEventListeners()
  }
}
