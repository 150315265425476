import { Controller } from '@hotwired/stimulus'

const ICON = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyMSAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS41MzY4IDIzLjAwNjhDMTQuMTc1OCAyMC44MzYzIDIwLjM2ODIgMTUuMjgwMSAyMC4zNjgyIDEwLjkzNDFDMjAuMzY4MiA1LjQxMTIzIDE1Ljg5MSAwLjkzNDA4MiAxMC4zNjgyIDAuOTM0MDgyQzQuODQ1MzIgMC45MzQwODIgMC4zNjgxNjQgNS40MTEyMyAwLjM2ODE2NCAxMC45MzQxQzAuMzY4MTY0IDE1LjI4MDEgNi41NjA1IDIwLjgzNjMgOS4xOTk1IDIzLjAwNjhDOS44ODQzNCAyMy41NzAxIDEwLjg1MiAyMy41NzAxIDExLjUzNjggMjMuMDA2OFpNMTAuMzY4MiAxMy45MzQxQzEyLjAyNSAxMy45MzQxIDEzLjM2ODIgMTIuNTkwOSAxMy4zNjgyIDEwLjkzNDFDMTMuMzY4MiA5LjI3NzIzIDEyLjAyNSA3LjkzNDA4IDEwLjM2ODIgNy45MzQwOEM4LjcxMTMxIDcuOTM0MDggNy4zNjgxNiA5LjI3NzIzIDcuMzY4MTYgMTAuOTM0MUM3LjM2ODE2IDEyLjU5MDkgOC43MTEzMSAxMy45MzQxIDEwLjM2ODIgMTMuOTM0MVoiIGZpbGw9IiMzMzU1RkYiLz4KPC9zdmc+Cg=='

export default class Map extends Controller {
  static values = {
    coordinates: { type: Array }
  }

  async connect () {
    await google.maps.importLibrary('maps')
    this.initMap()
  }

  initMap () {
    if (this.coordinatesValue.length === 0) {
      return
    }

    const options = {
      center: new google.maps.LatLng(...this.coordinatesValue),
      zoom: 15,
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_TOP
      }
    }

    this.map = new google.maps.Map(this.element, options)
    this.addMarkers()
  }

  addMarkers () {
    return new google.maps.Marker({
      icon: ICON,
      position: new google.maps.LatLng(...this.coordinatesValue),
      map: this.map
    })
  }
}
