import PlacesAutocomplete from 'stimulus-places-autocomplete'

const ICON = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyMSAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS41MzY4IDIzLjAwNjhDMTQuMTc1OCAyMC44MzYzIDIwLjM2ODIgMTUuMjgwMSAyMC4zNjgyIDEwLjkzNDFDMjAuMzY4MiA1LjQxMTIzIDE1Ljg5MSAwLjkzNDA4MiAxMC4zNjgyIDAuOTM0MDgyQzQuODQ1MzIgMC45MzQwODIgMC4zNjgxNjQgNS40MTEyMyAwLjM2ODE2NCAxMC45MzQxQzAuMzY4MTY0IDE1LjI4MDEgNi41NjA1IDIwLjgzNjMgOS4xOTk1IDIzLjAwNjhDOS44ODQzNCAyMy41NzAxIDEwLjg1MiAyMy41NzAxIDExLjUzNjggMjMuMDA2OFpNMTAuMzY4MiAxMy45MzQxQzEyLjAyNSAxMy45MzQxIDEzLjM2ODIgMTIuNTkwOSAxMy4zNjgyIDEwLjkzNDFDMTMuMzY4MiA5LjI3NzIzIDEyLjAyNSA3LjkzNDA4IDEwLjM2ODIgNy45MzQwOEM4LjcxMTMxIDcuOTM0MDggNy4zNjgxNiA5LjI3NzIzIDcuMzY4MTYgMTAuOTM0MUM3LjM2ODE2IDEyLjU5MDkgOC43MTEzMSAxMy45MzQxIDEwLjM2ODIgMTMuOTM0MVoiIGZpbGw9IiMzMzU1RkYiLz4KPC9zdmc+Cg=='

export default class extends PlacesAutocomplete {
  static targets = ['map']

  async connect () {
    if (typeof (google) !== 'undefined') {
      await google.maps.importLibrary('places')
      super.connect()

      this.mapLocation = new google.maps.LatLng(
        this.latitudeTarget.value,
        this.longitudeTarget.value
      )

      if (this.hasMapTarget) {
        this.#map()
        this.#marker()
      }
    }
  }

  initAutocomplete () {
    this.autocomplete = new google.maps.places.Autocomplete(this.addressTarget, this.autocompleteOptions)
    this.autocomplete.addListener('place_changed', this.placeChanged)
    this.addressTarget.addEventListener('input', this.#onAddressBlur.bind(this))
    this.addressTarget.addEventListener('blur', this.#onAddressBlur.bind(this))
  }

  placeChanged () {
    super.placeChanged()

    if (!this.place.geometry) {
      return
    }

    if (this.hasMapTarget) {
      this.#map().fitBounds(this.place.geometry.viewport)
      this.#map().setCenter(this.place.geometry.location)
      this.#marker().setPosition(this.place.geometry.location)
    }

    this.placeSelected = true

    this.#onAddressBlur()
  }

  #onAddressBlur () {
    setTimeout(() => {
      this.#geocode()
    }, 0)

    this.placeSelected = false
  }

  #geocode () {
    if (!this.placeSelected) {
      const address = this.addressTarget.value
      const geocoder = new google.maps.Geocoder()

      geocoder.geocode({ address }, (results, status) => {
        if (results && status === 'OK') {
          const addressComponents = results[0].address_components
          const geometry = results[0].geometry

          if (addressComponents !== undefined) {
            const formattedAddress = this.formatAddressComponents(addressComponents)
            this.setAddressComponents(formattedAddress)
          }

          if (geometry !== undefined) {
            this.setGeometry(geometry)

            if (this.hasMapTarget) {
              this.#map().fitBounds(geometry.viewport)
              this.#map().setCenter(geometry.location)
              this.#marker().setPosition(geometry.location)
            }
          }
        } else {
          this.setAddressComponents({})
          this.latitudeTarget.value = ''
          this.longitudeTarget.value = ''

          this.place = undefined

          if (this.hasMapTarget) {
            this.#map()
          }
        }
      })
    }

    this.dispatch('shareCoordinates', {
      detail: {
        lat: this.latitudeTarget.value,
        lng: this.longitudeTarget.value
      }
    })
  }

  #map () {
    if (this.latitudeTarget.value && this.longitudeTarget.value) {
      this.mapTarget.classList.remove('hidden')
    } else {
      this.mapTarget.classList.add('hidden')
    }

    if (this.map === undefined) {
      this.map = new google.maps.Map(this.mapTarget, {
        center: this.mapLocation,
        zoom: 17,
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_TOP
        }
      })
    }

    return this.map
  }

  #marker () {
    if (this.marker === undefined) {
      this.marker = new google.maps.Marker({
        icon: ICON,
        map: this.#map(),
        anchorPoint: new google.maps.Point(0, 0)
      })

      this.marker.setPosition(this.mapLocation)
    }

    return this.marker
  }
}
