import { Controller } from '@hotwired/stimulus'
import Imask from 'imask'

export default class ImaskController extends Controller {
  static values = { type: String }

  initialize () {
    switch (this.element.dataset.type) {
      case 'time':
        Imask(this.element, {
          overwrite: true,
          autofix: true,
          lazy: false,
          mask: 'HH:MM',
          blocks: {
            HH: {
              mask: Imask.MaskedRange,
              from: 0,
              to: 12,
              maxLength: 2
            },
            MM: {
              mask: Imask.MaskedRange,
              from: 0,
              to: 59,
              maxLength: 2
            }
          }
        })
        break

      case 'currency':
        Imask(this.element, {
          mask: '$num',
          lazy: true,
          blocks: {
            num: {
              mask: Number,
              scale: 2,
              thousandsSeparator: ' ',
              padFractionalZeros: true,
              normalizeZeros: true,
              radix: '.',
              mapToRadix: ['.'],
              max: 9999.99
            }
          }
        })
        break

      case 'phone':
        Imask(this.element, {
          mask: '(000) 000-0000',
          lazy: true,
          country: 'USA',
          blocks: {
            num: {
              mask: Number
            }
          }
        })
        break
    }
  }
}
