import { Controller } from '@hotwired/stimulus'

export default class PasswordController extends Controller {
  static targets = ['errorMessage', 'charactersMessage', 'specialCharacterMessage', 'numberMessage', 'iconErrored', 'iconSuccess']

  validate (event) {
    const password = event.target.value
    const hasEnoughCharacters = password.length >= 8
    const hasSpecialCharacter = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password)
    const hasNumber = /\d/.test(password)

    this.updateMessageStatus(hasEnoughCharacters, hasSpecialCharacter, hasNumber)
    this.updateIcon(hasEnoughCharacters, hasSpecialCharacter, hasNumber)
  }

  updateMessageStatus (hasEnoughCharacters, hasSpecialCharacter, hasNumber) {
    if (hasEnoughCharacters) {
      this.#isValid(this.charactersMessageTarget)
    } else {
      this.#isInvalid(this.charactersMessageTarget)
    }

    if (hasSpecialCharacter) {
      this.#isValid(this.specialCharacterMessageTarget)
    } else {
      this.#isInvalid(this.specialCharacterMessageTarget)
    }

    if (hasNumber) {
      this.#isValid(this.numberMessageTarget)
    } else {
      this.#isInvalid(this.numberMessageTarget)
    }

    if (hasEnoughCharacters && hasSpecialCharacter && hasNumber) {
      this.#isValid(this.errorMessageTarget)
    } else {
      this.#isInvalid(this.errorMessageTarget)
    }
  }

  updateIcon (hasEnoughCharacters, hasSpecialCharacter, hasNumber) {
    if (hasEnoughCharacters) {
      this.#showIcon(this.iconSuccessTargets[0])
      this.#hideIcon(this.iconErroredTargets[0])
    } else {
      this.#showIcon(this.iconErroredTargets[0])
      this.#hideIcon(this.iconSuccessTargets[0])
    }

    if (hasSpecialCharacter) {
      this.#showIcon(this.iconSuccessTargets[1])
      this.#hideIcon(this.iconErroredTargets[1])
    } else {
      this.#showIcon(this.iconErroredTargets[1])
      this.#hideIcon(this.iconSuccessTargets[1])
    }

    if (hasNumber) {
      this.#showIcon(this.iconSuccessTargets[2])
      this.#hideIcon(this.iconErroredTargets[2])
    } else {
      this.#showIcon(this.iconErroredTargets[2])
      this.#hideIcon(this.iconSuccessTargets[2])
    }
  }

  #showIcon (icon) {
    icon.classList.remove('hidden')
  }

  #hideIcon (icon) {
    icon.classList.add('hidden')
  }

  #isValid (target) {
    target.classList.remove('text-tertiary-600', 'text-red-400')
    target.classList.add('text-green-500', 'font-semibold')
  }

  #isInvalid (target) {
    target.classList.remove('text-tertiary-600', 'text-green-500', 'font-semibold')
    target.classList.add('text-red-400', 'font-normal')
  }
}
