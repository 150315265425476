import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['output', 'input']

  readURL () {
    const input = this.inputTarget
    const output = this.outputTarget
    const fileSizeInMB = input.files[0].size / (1024 * 1024)

    if (input.files && input.files[0] && fileSizeInMB <= 4) {
      const reader = new FileReader()

      reader.onload = () => {
        output.src = reader.result
      }

      reader.readAsDataURL(input.files[0])
    }
  }
}
