import { Controller } from '@hotwired/stimulus'
import { loadStripe } from '@stripe/stripe-js'

export default class extends Controller {
  static values = {
    secret: String,
    publicKey: String
  }

  static targets = ['paymentElement', 'addressElement', 'errors']

  async connect () {
    if (this.hasPaymentElementTarget && this.hasAddressElementTarget) {
      const stripe = await loadStripe(this.publicKeyValue)

      const elements = stripe.elements({
        clientSecret: this.secretValue,
        loader: 'always'
      })

      const addressElement = elements.create('address', { mode: 'billing' })
      const paymentElement = elements.create('payment')

      paymentElement.mount(this.paymentElementTarget)
      addressElement.mount(this.addressElementTarget)

      this.element.addEventListener('turbo:before-fetch-request', async (e) => {
        e.preventDefault()

        this.#hideErrors()

        // await stripe.confirmSetup
        const result = await stripe.confirmPayment({
          elements,
          redirect: 'if_required',
          confirmParams: {
            return_url: location.href
          }
        })

        if (result.error) {
          if (result.error.type !== 'validation_error') {
            this.#showErrors(result.error.message)
          }

          return
        }

        e.detail.resume()
      })
    }
  }

  #hideErrors () {
    this.errorsTarget.classList.add('hidden')
    this.errorsTarget.textContent = ''
  }

  #showErrors (message) {
    this.errorsTarget.classList.remove('hidden')
    this.errorsTarget.textContent = 'An error occurred! Your payment cannot be continued'
  }
}
