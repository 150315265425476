import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'

export function uppyInstance({ allowedFileTypes, endpoint, fieldName="image", minNumberOfFiles = 0, maxNumberOfFiles = 10, maxFileSize = 6000000 }) {
  const uppy = new Uppy({
    autoProceed: true,
    restrictions: {
      allowedFileTypes,
      minNumberOfFiles,
      maxNumberOfFiles,
      maxFileSize,
    },
  })

  uppy.use(XHRUpload, {
    endpoint,
    fieldName,
    formData: true,
    responseType: 'text',
    headers: {
      "Accept": "text/vnd.turbo-stream.html, text/html, application/xhtml+xml",
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
    },
    getResponseData(responseText, response) {
      // process the server response. In this case it's turbo_stream elements
      Turbo.renderStreamMessage(responseText)
      return response;
    }
  })

  return uppy
}

export function uploadedFileData(file, response) {
  return JSON.stringify(response.body)
}
