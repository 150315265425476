import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    seconds: String
  }

  static targets = ['days', 'hours', 'minutes', 'seconds', 'copy']

  connect () {
    this.#updateClock()
    this.timeinterval = setInterval(() => this.#updateClock(), 1000)
  }

  #getTimeRemaining () {
    const total = this.secondsValue

    const minutes = Math.floor(this.secondsValue / 60)
    const seconds = this.secondsValue % 60
    const hours = Math.floor((this.secondsValue / (1000 * 60 * 60)) % 24)
    const days = Math.floor(this.secondsValue / (1000 * 60 * 60 * 24))

    return {
      total,
      days,
      hours,
      minutes,
      seconds
    }
  }

  #updateClock () {
    const t = this.#getTimeRemaining()

    if (this.hasDaysTarget) {
      this.daysTarget.innerHTML = t.days
    }

    if (this.hasHoursTarget) {
      this.hoursTarget.innerHTML = ('0' + t.hours).slice(-2)
    }

    if (this.hasMinutesTarget) {
      this.minutesTarget.innerHTML = t.minutes
    }

    if (this.hasSecondsTarget) {
      this.secondsTarget.innerHTML = ('0' + t.seconds).slice(-2)
    }

    this.secondsValue = this.secondsValue - 1

    if (this.secondsValue < 0) {
      clearInterval(this.timeinterval)
      this.#handleTimeExpired()
    }
  }

  #handleTimeExpired () {
    if (this.secondsValue <= 0) {
      this.copyTarget.innerHTML = "Time's up! Please rebook for this event or find another event that suits you"
    }
  }
}
