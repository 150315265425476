import ScrollTo from 'stimulus-scroll-to'

export default class extends ScrollTo {
  connect () {
    super.connect()
    this.defaultOptions()
  }

  defaultOptions () {
    return {
      offset: 100,
      behavior: 'auto'
    }
  }
}
