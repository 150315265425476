import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'sortLink', 'button']

  connect () {
    this.toggleSortLinks()
    this.inputTarget.addEventListener('input', () => this.toggleSortLinks())
  }

  disconnect () {
    this.inputTarget.removeEventListener('input', () => this.toggleSortLinks())
  }

  toggleSortLinks () {
    if (this.inputTarget.value.trim() === '') {
      this.#disableButtons()
      this.sortLinkTargets.forEach(link => {
        link.dataset.href = link.href
        link.removeAttribute('href')
        link.classList.add('dropdown__link-disabled')
      })
    } else {
      this.buttonTargets.forEach(button => {
        button.disabled = false
        button.classList.remove('cursor-not-allowed')
      })

      this.sortLinkTargets.forEach(link => {
        if (link.dataset.href) {
          link.href = link.dataset.href
          link.classList.remove('dropdown__link-disabled')
        }
        if (!link.href.includes('longitude') || !link.href.includes('latitude')) {
          link.removeAttribute('href')
          link.classList.add('dropdown__link-disabled')
        }
      })
    }
  }

  toggleButton (e) {
    const { lat, lng } = e.detail

    if (!lat || !lng) {
      this.#disableButtons()
    } else {
      this.#enableButtons()
    }
  }

  #disableButtons () {
    this.buttonTargets.forEach(button => {
      button.disabled = true
      button.classList.add('cursor-not-allowed')
    })
  }

  #enableButtons () {
    this.buttonTargets.forEach(button => {
      button.disabled = false
      button.classList.remove('cursor-not-allowed')
    })
  }
}
