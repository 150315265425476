import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['startDate', 'endDate', 'registrationStartDate']

  setStartDate (e) {
    const { date } = e.detail

    const startDate = date.format('MM/DD/YYYY')

    this.endDateTarget.setAttribute('data-easepick-lock-min-date', startDate)
    this.#easepickController(this.endDateTarget).reload()

    this.registrationStartDateTarget.setAttribute('data-easepick-lock-max-date', startDate)
    this.#easepickController(this.registrationStartDateTarget).reload()
  }

  #easepickController (element) {
    return this.application.getControllerForElementAndIdentifier(element, 'easepick')
  }
}
