import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    // const len = this.element.value.length
    // this.element.focus()
    // this.element.setSelectionRange(len, len)
    this.scrollTid = setTimeout(() => this.element.focus(), 100)
  }
}
