import { Controller } from '@hotwired/stimulus'
import noUiSlider from 'nouislider'
import styles from '../stylesheets/vendors/nouislider.css'

export default class extends Controller {
  static targets = ['slider', 'minPrice', 'maxPrice', 'activitiesMinPrice', 'activitiesMaxPrice']

  connect () {
    this.minPriceTarget.addEventListener('focus', this.disableSliderUpdate.bind(this))
    this.minPriceTarget.addEventListener('blur', this.enableSliderUpdate.bind(this))
    this.maxPriceTarget.addEventListener('focus', this.disableSliderUpdate.bind(this))
    this.maxPriceTarget.addEventListener('blur', this.enableSliderUpdate.bind(this))
  }

  initialize () {
    const formatForSlider = {
      from: function (formattedValue) {
        return Number(formattedValue)
      },
      to: function (numericValue) {
        return parseInt(numericValue)
      }
    }

    this.slider = noUiSlider.create(this.sliderTarget, {
      css: styles,
      start: [this.minPriceTarget.value, this.maxPriceTarget.value],
      connect: true,
      range: {
        min: parseInt(this.activitiesMinPriceTarget.value),
        max: parseInt(this.activitiesMaxPriceTarget.value)
      },
      format: formatForSlider,
      tooltips: {
        to: function (numericValue) {
          return '$' + parseInt(numericValue)
        }
      }
    })

    // Update the input values when the slider value changes
    this.slider.on('update', this.updateInputValuesFromSlider.bind(this))

    this.minPriceTarget.addEventListener('input', this.updateSliderValues.bind(this))
    this.maxPriceTarget.addEventListener('input', this.updateSliderValues.bind(this))

    this.minPriceTarget.addEventListener('keypress', this.validateNumericInput)
    this.maxPriceTarget.addEventListener('keypress', this.validateNumericInput)
  }

  validateNumericInput (e) {
    const charCode = (e.which) ? e.which : e.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault()
    }
  }

  updateInputValuesFromSlider (values) {
    this.minPriceTarget.value = parseInt(values[0])
    this.maxPriceTarget.value = parseInt(values[1])
  }

  disableSliderUpdate () {
    this.slider.off('update', this.updateInputValuesFromSlider)
  }

  enableSliderUpdate () {
    this.slider.on('update', this.updateInputValuesFromSlider.bind(this))
    this.updateSliderValuesFromInput()
  }

  updateSliderValuesFromInput () {
    const values = [parseInt(this.minPriceTarget.value), parseInt(this.maxPriceTarget.value)]
    this.slider.set(values)
  }

  // Update the slider values when the input values change
  updateSliderValues (e) {
    const value = parseInt(e.target.value)
    if (e.target === this.minPriceTarget) {
      const values = [value, this.slider.get()[1]]
      this.slider.set(values)
    } else if (e.target === this.maxPriceTarget) {
      const values = [this.slider.get()[0], value]
      this.slider.set(values)
    }
  }
}
