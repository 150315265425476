import { Controller } from '@hotwired/stimulus'
import currency from 'currency.js'

export default class extends Controller {
  static targets = ['total']
  static values = {
    basePrice: { type: Number },
    totalPrice: { type: Number, default: 0 }
  }

  countAmount (e) {
    const currencyAmount = currency(this.totalPriceValue)

    if (e.target.checked) {
      this.totalPriceValue = currencyAmount.add(this.basePriceValue)
    } else {
      this.totalPriceValue = currencyAmount.subtract(this.basePriceValue)
    }

    this.totalTarget.textContent = currency(this.totalPriceValue).format()
  }
}
