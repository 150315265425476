import { Controller } from '@hotwired/stimulus'

export default class BannerController extends Controller {
  static targets = ['container']

  close () {
    this.#hide(this.containerTarget)
  }

  #hide (target) {
    target.classList.add('hidden')
  }
}
