import { Controller } from '@hotwired/stimulus'

export default class Select extends Controller {
  static targets = ['label', 'select']

  connect () {
    this.setLabelPosition()
  }

  setLabelPosition () {
    const selectedValue = this.selectTarget.selectedOptions[0].value

    if (selectedValue.length > 0) {
      this.labelTarget.classList.remove('form__label-centered')
    } else {
      this.labelTarget.classList.add('form__label-centered')
    }
  }
}
