import { Controller } from '@hotwired/stimulus'
import { tns } from 'tiny-slider'

export default class SliderController extends Controller {
  static targets = ['carousel']

  connect () {
    tns({
      container: this.carouselTarget,
      mode: 'carousel',
      startIndex: 1,
      center: true,
      nav: true,
      navAsThumbnails: true,
      autoWidth: true,
      controls: false,
      autoplayButtonOutput: false,
      autoplayTimeout: 4750,
      autoplay: true,
      autoplayHoverPause: true,
      loop: true,
      rewind: false,
      speed: 2250,
      mouseDrag: true,
      gutter: 8,
      responsive: {
        768: {
          items: 2,
          slideBy: 1
        }
      }
    })
  }
}
