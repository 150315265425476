import { Controller } from '@hotwired/stimulus'

export default class ProgressBar extends Controller {
  static targets = ['bar', 'input', 'checkbox', 'password', 'startPoint', 'endPoint']
  static values = {
    divider: { type: Number, default: 8 },
    initialProgress: Number
  }

  connect () {
    this.updateProgressBar()
  }

  updateProgressBar () {
    const filledInputs = this.inputTargets.filter(input => input.value.trim() !== '').length
    const filledCheckboxes = this.checkboxTargets.filter(checkbox => checkbox.checked).length > 0 ? 1 : 0
    const totalFilled = filledInputs + filledCheckboxes
    let progress = (totalFilled / this.dividerValue) * 100

    if (this.hasInitialProgressValue) {
      progress += this.initialProgressValue
    }

    this.barTarget.style.width = `${progress}%`
    this.updateStyles(progress)
  }

  updateStyles (progress) {
    if (progress === 100) {
      this.passwordTarget.classList.add('text-blue-400')
      this.endPointTarget.classList.add('bg-blue-400')
      this.startPointTarget.classList.add('text-blue-900')
    } else {
      this.passwordTarget.classList.remove('text-blue-400')
      this.endPointTarget.classList.remove('bg-blue-400')
      this.startPointTarget.classList.remove('text-blue-900')
    }
  }
}
