import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkbox']

  clearAll (e) {
    this.checkboxTargets.forEach((checkbox) => {
      if (checkbox.checked === true) {
        checkbox.checked = false
        checkbox.indeterminate = false
        this.#triggerInputEvent(checkbox)
      }
    })
  }

  #triggerInputEvent (checkbox) {
    const event = new Event('input', { bubbles: false, cancelable: true })

    checkbox.dispatchEvent(event)
  }
}
