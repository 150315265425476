import { Controller } from '@hotwired/stimulus'
import { addToArray, removeFromArray } from '~/utils/array'

export default class extends Controller {
  static values = {
    selectedAges: { type: Array, default: [] },
    selectedActivities: { type: Array, default: [] },
    selectedDates: { type: Array, default: [] },
    selectedOptions: { type: Array, default: [] },
    selectedPrice: { type: Array },
    activitiesRangePrice: { type: Array },
    selectedDistance: { type: String } // String because it can be 'unlimited_distance'
  }

  static targets = ['selectedDates', 'selectedAges', 'selectedActivities', 'selectedOptions', 'selectedDistance', 'selectedPrice']

  connect () {
    this.selectedPriceChanged()
  }

  selectedAgesValueChanged (value) {
    this.selectedAgesTargets.map((element) => {
      if (value.length > 0) {
        element.parentElement.classList.add('text-blue-400')
      } else {
        element.parentElement.classList.remove('text-blue-400')
      }
    })
  }

  selectedPriceChanged () {
    if (this.#arraysEqual(this.selectedPriceValue, this.activitiesRangePriceValue)) {
      this.selectedPriceTarget.parentElement.classList.remove('text-blue-400')
    } else {
      this.selectedPriceTarget.parentElement.classList.add('text-blue-400')
    }
  }

  #arraysEqual (a, b) {
    if (a.length !== b.length) return false

    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) return false
    }

    return true
  }

  selectedActivitiesValueChanged (value) {
    this.selectedActivitiesTargets.map((element) => {
      if (value.length > 0) {
        element.parentElement.classList.add('text-blue-400')
      } else {
        element.parentElement.classList.remove('text-blue-400')
      }
    })
  }

  selectedOptionsValueChanged (value) {
    this.selectedOptionsTargets.map((element) => {
      if (value.length > 0) {
        element.parentElement.classList.add('text-blue-400')
      } else {
        element.parentElement.classList.remove('text-blue-400')
      }
    })
  }

  selectedDatesValueChanged (value) {
    this.selectedDatesTargets.map((element) => {
      if (value.length > 0) {
        element.parentElement.classList.add('text-blue-400')
      } else {
        element.parentElement.classList.remove('text-blue-400')
      }
    })
  }

  selectedDistanceValueChanged (value) {
    const element = this.selectedDistanceTarget
    if (value === 'unlimited_distance' || Number(value)) {
      element.parentElement.classList.add('text-blue-400')
    } else {
      element.parentElement.classList.remove('text-blue-400')
    }
  }

  clearDates () {
    this.selectedDatesValue = []
  }

  setAges (e) {
    const value = e.target?.nextElementSibling?.textContent

    if (e.target?.checked) {
      this.selectedAgesValue = addToArray(this.selectedAgesValue, value)
    } else {
      this.selectedAgesValue = removeFromArray(this.selectedAgesValue, value)
    }
  }

  setActivities (e) {
    const value = e.target?.nextElementSibling?.textContent

    if (e.target?.checked) {
      this.selectedActivitiesValue = addToArray(this.selectedActivitiesValue, value)
    } else {
      this.selectedActivitiesValue = removeFromArray(this.selectedActivitiesValue, value)
    }
  }

  setOptions (e) {
    const value = e.target?.nextElementSibling?.textContent

    if (e.target?.checked) {
      this.selectedOptionsValue = addToArray(this.selectedOptionsValue, value)
    } else {
      this.selectedOptionsValue = removeFromArray(this.selectedOptionsValue, value)
    }
  }

  setDates (e) {
    const { start, end } = e.detail

    const startDate = start.format('MMM, DD')
    const endDate = end.format('MMM, DD')

    this.selectedDatesValue = [startDate, endDate]
  }
}
