import { Alert, Dropdown, Modal } from 'tailwindcss-stimulus-components'
import { registerControllers } from 'stimulus-vite-helpers'
import Autosave from 'stimulus-rails-autosave'
import CheckboxSelectAll from 'stimulus-checkbox-select-all'
import TextareaAutogrow from 'stimulus-textarea-autogrow'
import Clipboard from 'stimulus-clipboard'
import { stimulus } from '~/init'

stimulus.register('alert', Alert)
stimulus.register('autosave', Autosave)
stimulus.register('checkbox-select-all', CheckboxSelectAll)
stimulus.register('dropdown', Dropdown)
stimulus.register('local_modal', Modal)
stimulus.register('textarea-autogrow', TextareaAutogrow)
stimulus.register('clipboard', Clipboard)

const controllers = import.meta.glob('./**/*_controller.js', { eager: true })
registerControllers(stimulus, controllers)
