import { Dropdown } from 'tailwindcss-stimulus-components'
import { addToArray, removeFromArray } from '~/utils/array'

export default class Multiselect extends Dropdown {
  static targets = ['selected', 'label', 'input']
  static values = {
    selected: { type: Array, default: [] }
  }

  addToSelectedValues (e) {
    const value = parseInt(e.target?.nextElementSibling?.textContent, 10)

    if (!isNaN(value)) {
      if (e.target?.checked) {
        this.selectedValue = addToArray(this.selectedValue, value)
      } else {
        this.selectedValue = removeFromArray(this.selectedValue, value)
      }
    } else {
      const stringValue = e.target?.nextElementSibling?.textContent
      if (e.target?.checked) {
        this.selectedValue = addToArray(this.selectedValue, stringValue)
      } else {
        this.selectedValue = removeFromArray(this.selectedValue, stringValue)
      }
    }
  }

  selectedValueChanged () {
    this.selectedTarget.innerHTML = this.selectedValue.join(', ')
    this.#setLabelPosition()
  }

  async openValueChanged () {
    super.openValueChanged()

    if (this.openValue === true) {
      this.inputTarget.classList.add('form__input-select-focused')
    } else {
      this.inputTarget.classList.remove('form__input-select-focused')
    }
  }

  // Private
  #setLabelPosition () {
    if (this.selectedValue.length > 0) {
      this.labelTarget.classList.remove('form__label-centered')
    } else {
      this.labelTarget.classList.add('form__label-centered')
    }
  }
}
