import { Controller } from '@hotwired/stimulus'
import { put } from '@rails/request.js'
import Sortable from 'sortablejs'

export default class extends Controller {
  static targets = ['element']

  connect () {
    this.sortable = Sortable.create(this.element, {
      animation: 150,
      onEnd: this.end.bind(this)
    })
  }

  async end () {
    const data = new FormData()
    const url = this.data.get('url')

    this.elementTargets.map((element) => {
      const id = element.dataset.id
      data.append('ids[]', id)
    })

    await put(url, { body: data })
  }
}
