import { Controller } from '@hotwired/stimulus'
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/shift-away.css'

export default class TippyController extends Controller {
  static values = { content: String }

  initialize () {
    tippy(this.element, {
      theme: 'translucent',
      placement: 'top',
      animation: 'shift-away',
      arrow: true,
      content: this.contentValue,
      interactive: false,
      allowHTML: true,
      hideOnClick: true,
      trigger: 'mouseenter',
      delay: [300, 200],
      maxWidth: 300
    })
  }
}
