import { Controller } from '@hotwired/stimulus'

export default class AllCheckbox extends Controller {
  static targets = ['toggler', 'section', 'sectionAll']

  connect () {
    if (this.#isAll()) {
      this.togglerTarget.checked = true
    }
  }

  toggle (e) {
    this.sectionTargets.forEach((checkbox) => {
      checkbox.checked = e.target.checked
    })

    this.sectionTargets.forEach((checkbox) => {
      if (!checkbox.checked) {
        checkbox.checked = false
        checkbox.indeterminate = false
      }
    })

    this.sectionAllTargets.forEach((checkbox) => {
      checkbox.checked = e.target.checked
      checkbox.indeterminate = false
    })

    this.sectionAllTargets.forEach((checkbox) => {
      if (!checkbox.checked) {
        this.togglerTarget.checked = false
        this.togglerTarget.indeterminate = false
      }
    })
  }

  sectionChanged (e) {
    if (this.#isAll()) {
      this.togglerTarget.checked = true
    } else {
      this.togglerTarget.checked = false
    }
  }

  #isAll () {
    return this.sectionTargets.every(checkbox => checkbox.checked)
  }
}
