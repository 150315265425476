import { Controller } from '@hotwired/stimulus'

export default class CookiesController extends Controller {
  static values = {
    target: String
  }

  connect() {
    this.setCookie()
  }

  setCookie () {
    this.deleteCookie()
    document.cookie = `target=${this.targetValue}; path=/`;
  }

  deleteCookie () {
    document.cookie = 'target=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT';
  }
}
