import { Controller } from '@hotwired/stimulus'
import Informer from '@uppy/informer'
import ProgressBar from '@uppy/status-bar'
import DragDrop from '@uppy/drag-drop'

import { uppyInstance } from '../uppy'

export default class extends Controller {
  static targets = ['input']
  static values = {
    types: { type: Array },
    endpoint: { type: String },
    fieldName: { type: String, default: 'image' },
    minNumberOfFiles: { type: Number, default: 0 },
    maxNumberOfFiles: { type: Number, default: 4 },
    maxFileSize: { type: Number, default: 6000000 }
  }

  connect () {
    this.uppy = uppyInstance({
      types: this.typesValue,
      fieldName: this.fieldNameValue,
      endpoint: this.endpointValue,
      minNumberOfFiles: this.minNumberOfFilesValue,
      maxNumberOfFiles: this.maxNumberOfFilesValue,
      maxFileSize: this.maxFileSizeValue
    })
      .use(Informer, { target: this.element })
      .use(ProgressBar, { target: this.element })
      .use(DragDrop, { target: this.element, height: 200 })

    window.uppy = this.uppy // useful for testing

    return this.uppy
  }

  disconnect () {
    window.uppy = null
    this.uppy.close()
  }
}
